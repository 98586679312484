import { useEffect, useState } from "react";
import styles from "./popup-campaign.module.css";
import Image from "next/image";
import { TfiClose } from "react-icons/tfi";

import { useRouter } from "next/router";

const PopupCampaign = () => {
  const [showPopup, setShowPopup] = useState(false);

  const imageCampaign =
    "https://res.cloudinary.com/aurumlab/image/upload/v1724037149/aurumlab/banner/homepage/New_Premium_Box_-_Popup_f6pciq.webp";
  const urlCampaign = "/all-jewelries";

  useEffect(() => {
    // Set a timeout to show the popup after 5 seconds
    const timeoutId = setTimeout(() => {
      // Check if the 'showPopup' cookie is set
      const popupCookie = document.cookie.includes("showPopupCampaign=false");

      // Update state based on the cookie
      if (!popupCookie) {
        setShowPopup(true);
      }
    }, 2000);

    // Clear the timeout if the component unmounts before the timeout is reached
    return () => clearTimeout(timeoutId);
  }, []);

  const hidePopup = () => {
    // Set a cookie to remember that the popup has been closed
    document.cookie = "showPopupCampaign=false; Max-Age=" + 60 * 60 * 24 * 1;
    setShowPopup(false);
  };

  const router = useRouter();

  const gotoCampaign = () => {
    hidePopup();
    if (urlCampaign) {
      router.push(urlCampaign);
    }
  };

  return (
    <>
      {showPopup && (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <Image
              src={imageCampaign}
              width={1080}
              height={1080}
              quality={60}
              className={styles.campaign_img}
              alt="popup campaign"
              onClick={gotoCampaign}
            />
            <div className={styles.close_icon_popup} onClick={hidePopup}>
              <TfiClose />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupCampaign;
